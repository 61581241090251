
































































































.go-back-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 2px solid #000000;
  border-radius: 8px;
  cursor: pointer;
}
